import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { eventBus } from '../../../helpers/eventBus';
import {
  useConfirmNewPhoneNumberMutation,
  useGetPhoneNumberQuery,
  useGetProfileDetailsQuery,
  useUpdatePersonalDetailsMutation,
} from '../../../services/user';
import { ProfileForm } from './ProfileForm';
import { PhoneNumberValidationForm } from './PhoneNumberValidationForm';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import './profileDetails.scss';

export const ProfileDetails = () => {
  const { t } = useTranslation('application', { keyPrefix: 'profile' });
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading: userDetailsLoading, data: userDetails } = useGetProfileDetailsQuery();
  const { isLoading: phoneNumberLoading, data: phoneNumberData } = useGetPhoneNumberQuery();

  const [updatePersonalDetails, { error: personal_details_data }] = useUpdatePersonalDetailsMutation();
  const [confirmPhoneNumber, { error: otpConfirmError }] = useConfirmNewPhoneNumberMutation();

  const [formStatus, setFormStatus] = useState({
    type: '',
    message: '',
  });

  const handleConfirmPhoneNumber = async (code) => {
    try {
      await confirmPhoneNumber(code);
    } catch (e) {
      console.error(e);
    }
  };

  const handleResendCode = async (phoneNumber) => {
    try {
      await updatePersonalDetails({ phone_number: phoneNumber }).unwrap();
    } catch (error) {
      console.error('Error resending code:', error);
      setFormStatus({
        type: 'danger',
        message: t('phoneNumberDetails.resendCodeFailed'),
      });
    }
  };

  const handleSubmit = async (values) => {
    const {
      firstName, lastName, email, phoneNumber,
    } = values;

    try {
      let updates = {};

      if (phoneNumber !== phoneNumberData?.phone_number) {
        updates = { ...updates, phone_number: phoneNumber };
      }

      const validatePhoneNumber = () => new Promise((resolve, reject) => {
        const handleConfirmPhoneNumberWrapper = async (code) => {
          try {
            await handleConfirmPhoneNumber(code);
            resolve();
          } catch (e) {
            reject(new Error('Phone number is not valid.'));
          }
        };

        eventBus.publish('modal:open', {
          body: (
            <PhoneNumberValidationForm
              onSubmit={handleConfirmPhoneNumberWrapper}
              error={otpConfirmError}
              handleResendCode={handleResendCode}
              phoneNumber={phoneNumber}
            />
          ),
          alertType: true,
        });
      });

      if (updates.phone_number) {
        await validatePhoneNumber();
      }

      if (firstName !== userDetails?.first_name) {
        updates = { ...updates, first_name: firstName };
      }
      if (lastName !== userDetails?.last_name) {
        updates = { ...updates, last_name: lastName };
      }
      if (email !== userDetails?.email) {
        updates = { ...updates, email };
      }

      if (Object.keys(updates).length > 0) {
        await updatePersonalDetails(updates).unwrap();
        setFormStatus({
          type: 'success',
          message: t('profileDetails.updateSuccess'),
        });
      }
    } catch (error) {
      const errorMessage = Object.values(error?.data?.errors)?.[0]?.[0];
      setFormStatus({
        type: 'danger',
        message: errorMessage,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    const result = searchParams.get('email_confirmation_result');
    let timeOut = null;

    if (result === 'success') {
      setFormStatus({
        type: 'success',
        message: t('emailDetails.updateSuccess'),
      });
      toast(t('emailDetails.updateSuccess'), {
        type: 'success',
      });
    } else if (result === 'failure') {
      setFormStatus({
        type: 'danger',
        message: t('emailDetails.updateFailed'),
      });
      toast(t('emailDetails.updateFailed'), {
        type: 'error',
      });
    }

    setSearchParams('');
    timeOut = setTimeout(() => {
      setFormStatus({ type: '', message: '' });
    }, 15000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchParams, setSearchParams, t]);

  if (userDetailsLoading || phoneNumberLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="profile-subpage profile-details">
      <ProfileForm
        onSubmit={handleSubmit}
        firstName={userDetails?.first_name}
        lastName={userDetails?.last_name}
        email={userDetails?.email}
        initialPhoneNumber={phoneNumberData?.phone_number}
        formStatus={formStatus}
        error={personal_details_data?.data?.errors?.phone_number[0]}
      />
    </div>
  );
};
