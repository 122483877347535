import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GiftCodeInput } from '../../../components/inputs/Input/GiftCodeInput';
import { setDiscountCode, setOrderPrices, setPaymentData } from '../../../features/checkout/checkoutSlice';
import { useDebounce } from '../../../hooks/useDebounce';
import { useApplyDiscountCodeMutation } from '../../../services/order';

export const CheckoutGiftCode = () => {
  const currentStep = useSelector((state) => state.checkout.currentStep);
  const paymentData = useSelector((state) => state.checkout.paymentData);
  const discountCodeUsed = useSelector((state) => state.checkout.discountCode);
  const orderPrices = useSelector((state) => state.checkout.orderPrices);
  const [giftCodeValue, setGiftCodeValue] = useState('');
  const [applyGiftCode, {
    data: discountCodeData, isLoading, error: discountCodeError, isSuccess, reset,
  }] = useApplyDiscountCodeMutation();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const debouncedGiftCodeValue = useDebounce(giftCodeValue, 2000);

  const initialOrderPrices = useRef(null);

  useEffect(() => {
    const hasOrderPrices = orderPrices && Object.values(orderPrices).some(Boolean);
    const isStepTwo = currentStep === 2;
    if (!initialOrderPrices.current && hasOrderPrices && isStepTwo) {
      // eslint-disable-next-line immutable/no-mutation
      initialOrderPrices.current = { ...orderPrices };
    }
  }, [orderPrices, currentStep]);

  useEffect(() => {
    if (currentStep !== 2) return;

    if (debouncedGiftCodeValue && debouncedGiftCodeValue !== discountCodeUsed) {
      applyGiftCode(debouncedGiftCodeValue)
        .unwrap()
        .catch((e) => console.error(e));
      dispatch(setDiscountCode(null));
    } else if (!debouncedGiftCodeValue && discountCodeUsed) {
      dispatch(setDiscountCode(null));
      setError(null);
    }
  }, [debouncedGiftCodeValue, currentStep]);

  useEffect(() => {
    if (discountCodeError) {
      const { status, data: { error: discountCodeErr, errors } } = discountCodeError;
      if (status === 404) {
        setError([discountCodeErr]);
        dispatch(setOrderPrices(initialOrderPrices.current));
        dispatch(setPaymentData({
          ...paymentData,
          order: {
            ...paymentData.order,
            totalPrice: initialOrderPrices.current.totalPrice,
            vatPrice: initialOrderPrices.current.vatPrice,
            summaryPrice: initialOrderPrices.current.summaryPrice,
            discountValue: 0.0,
          },
          amount: initialOrderPrices.current.summaryPrice,
        }));
        dispatch(setDiscountCode(null));
      } else if (status === 422) {
        const err = Object.values(errors);
        if (err.find((el) => el[0].startsWith('translation missing'))) return;
        setError(Object.values(errors));
      }
    }

    if (isSuccess) {
      dispatch(
        setOrderPrices({
          totalPrice: discountCodeData.new_total_price,
          vatPrice: discountCodeData.new_vat_price,
          summaryPrice: discountCodeData.new_summary_price,
          discountValue: discountCodeData.discount_value,
        }),
      );
      dispatch(setPaymentData({
        ...paymentData,
        order: {
          ...paymentData.order,
          totalPrice: discountCodeData.new_total_price,
          vatPrice: discountCodeData.new_vat_price,
          summaryPrice: discountCodeData.new_summary_price,
          discountValue: discountCodeData.discount_value,
        },
        amount: discountCodeData.new_summary_price,
      }));
      dispatch(setDiscountCode(giftCodeValue));
      setError(null);
    }
  }, [discountCodeError, isSuccess]);

  useEffect(() => {
    const code = discountCodeUsed || paymentData?.usedDiscountCode;
    if (code) {
      setGiftCodeValue(code);
      reset();
    }
  }, [discountCodeUsed, paymentData]);

  useEffect(() => {
    if (giftCodeValue !== discountCodeUsed) setError(null);
  }, [discountCodeUsed, giftCodeValue]);

  return (
    currentStep === 2 && (
      <GiftCodeInput
        isLoading={isLoading}
        onGiftCodeChange={setGiftCodeValue}
        error={error}
        success={isSuccess || discountCodeUsed}
        value={giftCodeValue}
      />
    )
  );
};
